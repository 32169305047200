import * as ex from 'excalibur';
import { HealthPowerUp } from './HealthPowerUp';

export enum BulletSource {

    player,
    alien
}

export class Bullet extends ex.Actor {

    private bulletSpeed: number;
    private bulletSource: BulletSource;

    constructor(x: number, y: number, bulletSource: BulletSource, width: number, height: number, color: ex.Color) {
        super(x, y, width, height, color);
        this.bulletSpeed = 10;
        this.bulletSource = bulletSource;

        this.collisionType = ex.CollisionType.Passive;
        this.on('precollision', function (evt) {

            // we don't care about bullet collisions with powerups
            if (evt.other instanceof HealthPowerUp) {
                return;
            }

            this.kill();
        });
    }

    public update(game: ex.Engine, delta: number) {
        super.update(game, delta); // call base update logic

        // move bullet
        if (this.bulletSource === BulletSource.alien) {
            this.y += this.bulletSpeed;
        } else {
            this.y -= this.bulletSpeed;
        }

        // out of bounds
        if (this.y < 0) {
            this.kill();
        }

    }
}