import * as ex from "excalibur";

export enum toggleButtonState {
    NotPressed,
    Hover,
    Pressed,
    NotPressedToggled,
    HoverToggled,
    PressedToggled
}

function toggleButtonEnterAnimation(toggleButton: ToggleButton) {
    if (toggleButton.isToggled() === true) {
        toggleButton.setDrawing(toggleButtonState.HoverToggled);
    } else {
        toggleButton.setDrawing(toggleButtonState.Hover);
    }
}

function toggleButtonExitAnimation(toggleButton: ToggleButton) {
    if (toggleButton.isToggled()) {
        toggleButton.setDrawing(toggleButtonState.NotPressedToggled);
    } else {
        toggleButton.setDrawing(toggleButtonState.NotPressed);
    }
}

function toggleButtonPressedAnimation(toggleButton: ToggleButton) {
    if (toggleButton.isToggled()) {
        toggleButton.setDrawing(toggleButtonState.Pressed);
    } else {
        toggleButton.setDrawing(toggleButtonState.PressedToggled);
    }
}

export class ToggleButton extends ex.Actor {

    private readonly toggled: boolean;

    constructor() {
        super();

        this.enableCapturePointer = true;
        this.capturePointer.captureMoveEvents = true;
        this.toggled = false;
    }

    isToggled(): boolean {
        return this.toggled;
    }

    addTexture(key: toggleButtonState, texture: ex.Texture) {
        this.addDrawing(key, texture.asSprite());
    }

    onClicked(toggleHandler: any, nonToggleHandler: any) {
        this.on('pointerup', function () {
            if (this.toggled) {
                toggleHandler();
            } else {
                nonToggleHandler();
            }
            this.toggled = !this.toggled;
        });
    }

    onPressDown(handler: any) {
        this.on('pointerdown', function () {
            handler();
            toggleButtonPressedAnimation(this);
        });
    }

    onHover(handler: any) {
        this.on('pointerenter', function () {
            handler();
            toggleButtonEnterAnimation(this);
        });
    }

    onStopHover(handler: any) {
        this.on('pointerleave', function () {
            handler();
            toggleButtonExitAnimation(this);
        });
    }
}