import * as ex from 'excalibur';

export enum buttonState {
    NotPressed,
    Hover,
    Pressed
}

function buttonEnterAnimation(button: Button) {
    button.setDrawing(buttonState.Hover);
}

function buttonExitAnimation(button: Button) {
    button.setDrawing(buttonState.NotPressed);
}

function buttonPressedAnimation(button: Button) {
    button.setDrawing(buttonState.Pressed);
}

export class Button extends ex.Actor {

    constructor() {
        super();
        this.enableCapturePointer = true;
        this.capturePointer.captureMoveEvents = true;
    }

    addTexture(key: buttonState, texture: ex.Texture) {
        this.addDrawing(key, texture.asSprite());
    }

    onClicked(handler: any) {
        this.on('pointerup', function () {
            handler();
        });
    }

    onPressDown(handler: any) {
        this.on('pointerdown', function () {
            handler();
            buttonPressedAnimation(this);
        });
    }

    onHover(handler: any) {
        this.on('pointerenter', function () {
            handler();
            buttonEnterAnimation(this);
        });
    }

    onStopHover(handler: any) {
        this.on('pointerleave', function () {
            handler();
            buttonExitAnimation(this);
        });
    }
}