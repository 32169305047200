export default class GameConfig {
    alienMovementSpeed: number;
    spawnRate: number;
    fireRate: number;
    initialHealth: number;
    powerUpSpawnPropability: number;
    powerUpHealValue: number;
    playerSpeed: number;

    /**
     * 
     * @param alienMovementSpeed the speed an aline moves in one update cycle
     * @param spawnRate higher spawnrate = more aliens, a alien spawns with 1% each frame * spawnrate
     * @param fireRate  higher fireRate = more bullets shot by Aliens, a alien fires with 1% each frame * fireRate
     * @param initialHealth the initial health of the player. A hit by a bullet looses you 10 hp
     * @param playerSpeed the distance the player moves each update cycle default should be 30
     * @param powerUpSpawnPropability the percentage a powerUp spawns default should be 5% = 0.05
     * @param powerUpHealValue  the amount one powerup heals the player
     */

    constructor(alienMovementSpeed: number, spawnRate: number, fireRate: number, initialHealth: number, playerSpeed: number, powerUpSpawnPropability: number, powerUpHealValue: number) {
        this.alienMovementSpeed = alienMovementSpeed;
        this.spawnRate = spawnRate;
        this.fireRate = fireRate;
        this.initialHealth = initialHealth;
        this.powerUpHealValue = powerUpHealValue;
        this.powerUpSpawnPropability = powerUpSpawnPropability;
        this.playerSpeed = playerSpeed;
    }
}