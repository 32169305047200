
import * as ex from 'excalibur';
import { KeyEvent } from 'excalibur/dist/Input';
import { Input, Vector } from 'excalibur';
import { Resources, gameLevelDuration } from './Resources';
import { GameScene } from './GameScene';
import GameConfig from './GameConfig';

export enum DisplayMessage {

    victory,
    lost
}

export class LevelTransitionScene extends ex.Scene {

    private level: number;
    private difficulty: String;
    private config: GameConfig;
    private nextDisplayMessage: DisplayMessage;
    private destinationKey: string;

    constructor() {
        super();
    }


    /**
     * 
     * @param level select the next level. If you enter a number > 3 the level will be reset to one.
     */
    public setLevel(level: number) {
        if (level >= 1 && level <= 3)
            this.level = level;
        else
            this.level = 1;
    }

    public getLevel(): number {
        return this.level;
    }

    public setDifficulty(difficulty: String) {
        this.difficulty = difficulty;
    }

    public getDifficulty(): String {
        return this.difficulty;
    }

    /**
     * 
     * @param dpm is an enum. You can select if you lost the game or goto the next level
     */
    public setDisplayMessage(dpm: DisplayMessage) {
        this.nextDisplayMessage = dpm;
    }

    /**
     * 
     * @param config sets the config file for the next level
     */
    public setConfig(config: GameConfig) {
        this.config = config;
    }


    private showLost(game: ex.Engine) {
        let lost = new ex.Actor(game.halfDrawWidth, game.halfDrawHeight, Resources.victoryTexture.width, Resources.victoryTexture.height);
        lost.addDrawing('lost', Resources.lostTexture.asSprite());
        this.destinationKey = 'MainMenu';
        lost.scale = new Vector(0.75, 0.75);
        game.remove('Game');
        game.add(lost);
    }

    private showVictory(game: ex.Engine) {
        // we passed level show victory screen
        let vic = new ex.Actor(game.halfDrawWidth, game.halfDrawHeight, Resources.victoryTexture.width, Resources.victoryTexture.height);
        vic.addDrawing('victory', Resources.victoryTexture.asSprite());
        vic.scale = new Vector(0.75, 0.75);
        this.destinationKey = 'Game';
        game.add(vic);
        game.removeScene('Game');
        game.addScene('Game', new GameScene(gameLevelDuration, this.config, this.level));
    }

    public onInitialize(game: ex.Engine) {

        // we lost
        if (this.nextDisplayMessage === DisplayMessage.lost) {
            this.showLost(game);

        // we won
        } else {
            this.showVictory(game);
        }

        // switch to destination scene. Is set in showVictory and showLost
        game.input.keyboard.on("press", (evt: KeyEvent) => {

            if ((evt.key === Input.Keys.C) && this.isCurrentScene()) {
                game.goToScene(this.destinationKey);
            }

        });

    }
}