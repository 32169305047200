import * as ex from 'excalibur';
import {Resources} from './Resources';
import {Button, buttonState} from "./actors/Button";

export class HelpScene extends ex.Scene {

    private readonly UTL_BUTTON_PADDING: number;
    private readonly UTL_BUTTON_HEIGHT: number;
    private readonly UTL_BUTTON_WIDTH: number;
    private readonly UTL_BUTTON_SCALE: number;

    private readonly backgroundAct: ex.Actor;
    private readonly mainMenuButton: Button;

    constructor() {
        super();

        this.backgroundAct = new ex.Actor();
        this.mainMenuButton = new Button();

        this.UTL_BUTTON_PADDING = 50;
        this.UTL_BUTTON_HEIGHT = 210;
        this.UTL_BUTTON_WIDTH = 800;
        this.UTL_BUTTON_SCALE = 0.3;
    }

    public onInitialize(game: ex.Engine): void {
        game.add(this.backgroundAct);
        game.add(this.mainMenuButton);

        this.backgroundAct.addDrawing(Resources.helpSceneTexture);
        this.backgroundAct.anchor.setTo(0, 0);
        this.backgroundAct.scale.setTo(0.67,0.67);

        this.mainMenuButton.addTexture(buttonState.NotPressed, Resources.mainMenuButton);
        this.mainMenuButton.addTexture(buttonState.Hover, Resources.mainMenuButtonHover);
        this.mainMenuButton.addTexture(buttonState.Pressed, Resources.mainMenuButtonPressed);

        this.mainMenuButton.setWidth(this.UTL_BUTTON_WIDTH);
        this.mainMenuButton.setHeight(this.UTL_BUTTON_HEIGHT);
        this.mainMenuButton.scale.setTo(this.UTL_BUTTON_SCALE, this.UTL_BUTTON_SCALE);
        this.mainMenuButton.x = game.drawWidth - (this.mainMenuButton.getWidth() * this.UTL_BUTTON_SCALE * 1.5) - this.UTL_BUTTON_PADDING;
        this.mainMenuButton.y = game.drawHeight - (this.mainMenuButton.getHeight() * this.UTL_BUTTON_SCALE) - this.UTL_BUTTON_PADDING;

        this.mainMenuButton.onClicked(function () {
            game.goToScene('MainMenu');
        });
        this.mainMenuButton.onHover(function () {
        });
        this.mainMenuButton.onStopHover(function () {
        });
        this.mainMenuButton.onPressDown(function () {
        });
    }

    // each time the scene is entered (Engine.goToScene)
    public onActivate() {
    }

    // each time the scene is exited (Engine.goToScene)
    public onDeactivate() {
    }
}