import * as ex from 'excalibur';

export class LevelProgressIndicator extends ex.Actor{

    /*
     * Porgress between 0 and 1.0  
     */
    private progress : number;
    public background : ex.Actor;
    public maxWidth: number;

    constructor(x?: number, y?: number, width?: number, height?: number, color?: ex.Color, overlap?: number) {
        super(x, y, 0, height, color);
        this.anchor.setTo(0, 0);
        this.maxWidth = width;

        if(overlap == null || overlap == undefined)
            overlap = 4;

        this.background = new ex.Actor(x - overlap, y - overlap, width + 2 * overlap, height + 2 * overlap, color.darken(0.7));
        this.background.anchor.setTo(0, 0);
    }
    
    public update(engine: ex.Engine, delta: number) {
        super.update(engine, delta); // call base update logic
        this.setWidth(this.maxWidth * this.progress);
    }

    /**
     * 
     * @param porgress 0 = 0% and 1.0 = 100%
     */
    public setProgress(porgress : number) {
        this.progress = porgress;
    }

    /**
     * 
     * porgress 0 = 0% and 1.0 = 100%
     */
    public getProgress(): number {
        return this.progress;
    }


}