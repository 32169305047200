import * as ex from 'excalibur';
import {Resources, loader} from './Resources';
import {MainMenuScene} from './MainMenuScene';
import {HelpScene} from "./HelpScene";
import { runInDebugContext } from 'vm';
import { Debug } from 'excalibur';

// Create an instance of the engine.
const game = new ex.Engine({
    width: 1280,
    height: 720,
    displayMode: ex.DisplayMode.Position,
    position: 'top left',
});

//add Scenes to game
game.add('MainMenu', new MainMenuScene());
game.add('Help', new HelpScene());
game.isDebug = false;


loader.logo = Resources.loaderLogo.path;
loader.logoWidth = 800;
loader.logoHeight = 125;
loader.backgroundColor = '#030534';

// Start the engine to begin the game.
game.start(loader).then(function () {
    game.goToScene('MainMenu');
});