
import * as ex from 'excalibur';
import { Resources } from '../Resources';
import { CollisionType, Vector } from 'excalibur';

export class HealthPowerUp extends ex.Actor {

    constructor(x: number, y: number, witdh?: number, height?: number, color?: ex.Color) {
        super(x, y, witdh, height, color);

        this.addDrawing(Resources.powerUpHealth);
        this.setWidth(Resources.powerUpHealth.width);
        this.setHeight(Resources.powerUpHealth.height);
        this.scale = new Vector(.1, .1);
    }

    public onInitialize(game: ex.Engine){
        this.collisionType = CollisionType.Passive;
    }
}