import * as ex from 'excalibur';
import { Player } from './actors/Player';
import { Alien } from './actors/Alien';
import { LevelProgressIndicator } from './actors/LevelProgressIndicator';
import { Vector } from 'excalibur';
import { Resources, config } from './Resources';
import { Healthbar } from './actors/Healthbar';
import GameConfig from './GameConfig';
import { LevelTransitionScene, DisplayMessage } from './LevelTransitionScene';
import { HealthPowerUp } from './actors/HealthPowerUp';


export class GameScene extends ex.Scene {

    private player: Player;
    private healthbar: Healthbar;
    private background: ex.Actor;
    private levelProgressIndicator: LevelProgressIndicator;
    private levelStartTime: number;
    private levelDurationInMilliseconds: number;
    private config: GameConfig;
    private level: number;

    /**
     * 
     * @param levelDurationInMilliseconds level will end after this time. Backgroundmovementspeed depends on this value
     * @param config the game will be created by this config file
     * @param level a number between 1 and 3
     */
    constructor(levelDurationInMilliseconds: number, config: GameConfig, level: number) {
        super();
        this.player = new Player(-10, -10, 30, 30, ex.Color.Red, config);
        this.config = config;
        this.level = level;
        this.levelDurationInMilliseconds = levelDurationInMilliseconds;
    }

    /**
     * 
     * @param background a texture to add as game backgroud
     * 
     * @param scale to scale a background texture up or down
     */
    private addAndScaleBackground(background: ex.Texture, scale: Vector) {
        this.background.addDrawing(background);
        this.background.setWidth(background.width);
        this.background.setHeight(background.height);
        this.background.scale = scale;
        this.background.anchor.setTo(0.5, 1);
    }

    // start-up logic, called once
    public onInitialize(game: ex.Engine) {


        // create LevelProgressBar
        // calc x Pos (progressbar should be 3/5 of the game screen)
        let xProgressBar = game.drawWidth / 5;
        // yProgressBar starts at 10% of total gameHeight
        let yProgressBar = game.drawHeight / 10;
        this.levelProgressIndicator = new LevelProgressIndicator(xProgressBar, yProgressBar, game.drawWidth * 3 / 5, 20, ex.Color.Green);
        this.levelStartTime = new Date().getTime();

        // create Healthbar
        this.healthbar = new Healthbar(game.drawWidth / 10, game.drawHeight * 9 / 10, game.drawWidth / 5, 20, this.config.initialHealth, ex.Color.Green);
        this.healthbar.anchor.setTo(0, 0);

        // set background
        this.background = new ex.Actor(game.halfDrawWidth, game.drawHeight, game.drawWidth, game.drawHeight);

        // select background image depending on current level
        switch (this.level) {
            case 1: this.addAndScaleBackground(Resources.gameBackground1, new Vector(.72, .72)); break;
            case 2: this.addAndScaleBackground(Resources.gameBackground2, new Vector(.72, .72)); break;
            case 3: this.addAndScaleBackground(Resources.gameBackground3, new Vector(.72, .72)); break;
        }

        // add all game components
        game.add(this.background);
        game.add(this.levelProgressIndicator.background);
        game.add(this.levelProgressIndicator);
        game.add(this.healthbar.background);
        game.add(this.healthbar);
        game.add(this.player);
        
    }

    // each time the scene is exited (Engine.goToScene)
    public onDeactivate() {

        // do cleanup
        this.player.kill();
        this.healthbar.reset();
    }

    public update(game: ex.Engine, delta: number) {
        super.update(game, delta); // call base update logic

        // update healthbar
        this.healthbar.setCurrentHealth(this.player.getHealth());

        // kill player if health is 0 or below
        // show lost screen
        if (this.healthbar.getCurrentHealth() <= 0) {
            let transitionScene = new LevelTransitionScene();
            transitionScene.setDisplayMessage(DisplayMessage.lost);
            game.addScene('transitionNextLevel', transitionScene);
            game.goToScene('transitionNextLevel');

        }

        // move background
        this.background.y = (this.background.getHeight() - game.drawHeight) * this.levelProgressIndicator.getProgress() + game.drawHeight;

        // update LevelProgressIndicator
        let currentTime = new Date().getTime();
        let progress = (currentTime - this.levelStartTime) / this.levelDurationInMilliseconds;
        this.levelProgressIndicator.setProgress(progress);

        // level Completeted
        if (progress >= 1) {
            let transitionScene = new LevelTransitionScene();
            transitionScene.setConfig(this.config);
            transitionScene.setLevel(this.level + 1);
            game.addScene('transitionNextLevel', transitionScene);
            game.goToScene('transitionNextLevel');

        }

        // spawn Aliens
        for (let i = 0; i < this.config.spawnRate; i++) {
            if (Math.random() * 100 > 99) {
                let x = (Math.random() * game.drawWidth);
                let y = Math.random() * 50;
                let alien = new Alien(x, y, this.config, this.level, 50, 50, ex.Color.DarkGray);
                alien.setPlayer(this.player);

                // add alien to game
                game.add(alien);
            }
        }

    }
}