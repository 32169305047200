import * as ex from 'excalibur';
import { CollisionType, Vector } from 'excalibur';
import { Resources, explosionSpriteSheet, gameLevelDuration } from '../Resources';
import { Player } from './Player';
import { Bullet, BulletSource } from './Bullet';
import GameConfig from '../GameConfig';
import { SoundHandler } from "../SoundHandler";
import { HealthPowerUp } from './HealthPowerUp';



export class Alien extends ex.Actor {

    private alienSpeed: number;
    private alienFireDelay: number;
    private player: Player;
    private alienTextures: Array<ex.Texture>;
    private explode: ex.Animation;
    private health: number;
    private powerUpSpawnPropability: number;

    constructor(x: number, y: number, config: GameConfig, level: number,  width?: number, height?: number, color?: ex.Color) {
        super(x, y, width, height, color);
        this.alienSpeed = config.alienMovementSpeed;
        this.alienFireDelay = config.fireRate;
        this.collisionType = CollisionType.Passive;
        this.powerUpSpawnPropability = config.powerUpSpawnPropability;
    
        this.alienTextures = new Array();
        // set Alien Texture
        this.alienTextures[0] = Resources.enemy1;
        this.alienTextures[1] = Resources.enemy2;
        this.alienTextures[2] = Resources.enemy3;
        this.alienTextures[3] = Resources.enemy4;
        this.alienTextures[4] = Resources.enemy5;
        this.alienTextures[5] = Resources.enemy6;
        this.alienTextures[6] = Resources.enemy7;
        this.alienTextures[7] = Resources.enemy8;
        this.alienTextures[8] = Resources.enemy9;
        this.alienTextures[9] = Resources.enemy10;
        this.alienTextures[10] = Resources.enemy11;
        this.alienTextures[11] = Resources.enemy12;

        //calc leveldepending alienTexture
        let rndNumber = Math.floor((Math.random() * 4)) + 4 * (level - 1);

        // big alien
        if (rndNumber >= 10) {
            this.health = 30;

        // small alien
        } else {
            this.health = 10;
        }

        this.addDrawing(this.alienTextures[rndNumber]);
        this.setWidth(this.alienTextures[rndNumber].width);
        this.setHeight(this.alienTextures[rndNumber].height);
        this.scale = new Vector(0.3, 0.3);
    }


    /**
     * 
     * @param player used for alienAI. If no player is set the alien will move straight down
     */
    public setPlayer(player: Player) {
        this.player = player;
    }

    private killAlien() {
        this.explode.play(this.x, this.y);
        SoundHandler.playSound(Resources.invaderKilledSound);
        this.kill();

    }

    /**
     * alien AI a alien is always focused to crash into the palyer
     */
    private alienAIMovement() {
        let pX = this.player.x;
        let pY = this.player.y;
        let aX = this.x;
        let aY = this.y;

        // move in x direction
        let dirX = pX - aX;
        if (dirX < 0) {
            this.x = this.x - this.alienSpeed;
        } else if (dirX > 0) {
            this.x = this.x + this.alienSpeed;
        }

        // move in y direction
        let dirY = pY - aY;
        if (dirY < 0) {
            this.y = this.y - this.alienSpeed;
        } else if (dirY > 0) {
            this.y = this.y + this.alienSpeed;
        }
    }

    /**
     * 
     * this method should only be called by the engine
     */
    public onInitialize(game: ex.Engine) {

        // set alien collision handling
        this.on('precollision', function (evt) {
            
            // we don't care about alien collisions with powerups
            if (evt.other instanceof HealthPowerUp) {
                return;
            }

            this.health -= 10;
            
            // alien still alive?
            if (this.health <= 0) {

                // spawn power up?
                if(Math.random() < this.powerUpSpawnPropability) {
                    let powerUp = new HealthPowerUp(this.x, this.y);
                    game.add(powerUp);
                }
                this.killAlien();
            }
        });


        // create explode animation
        this.explode = explosionSpriteSheet.getAnimationForAll(game, 150);
        this.explode.loop = false;
        this.explode.scale = new Vector(.5, .5);
        this.explode.anchor.setTo(.5, .5);
        this.addDrawing('explode', this.explode);

    }
    public update(game: ex.Engine, delta: number) {
        super.update(game, delta); // call base update logic

        // move alien
        if (this.player != null || this.player != undefined) {
            this.alienAIMovement();
            // no player is set
        } else {
            this.y = this.y + this.alienSpeed;
        }

        // alien shooting
        for (let i = 0; i < this.alienFireDelay; i++) {
            if ((Math.random() * 100) > 99) {
                let bullet = new Bullet(this.x, this.y + this.getHeight(), BulletSource.alien, 4, 8, ex.Color.Chartreuse);
                game.add(bullet);
                SoundHandler.playSound(Resources.ufoShootSound);
            }
        }

        // alien went out of bounds
        // kill it!
        if (this.y > game.drawHeight) {
            this.kill();
        }

    }


}