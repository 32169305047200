import * as ex from 'excalibur';
import { KeyEvent } from 'excalibur/dist/Input';
import { Input, Vector, CollisionType } from 'excalibur';
import { Resources, explosionSpriteSheet } from '../Resources';
import { Bullet, BulletSource } from './Bullet';
import {SoundHandler} from "../SoundHandler";
import { HealthPowerUp } from './HealthPowerUp';
import GameConfig from '../GameConfig';

export class Player extends ex.Actor {

    private playerSpeed: number;
    private health: number;
    private initialHealth;
    private powerUpHealValue;

    constructor(x: number, y: number, width: number, height: number, color: ex.Color, config: GameConfig) {
        super(x, y, width, height, color);
        this.playerSpeed = config.playerSpeed;
        this.initialHealth = config.initialHealth;
        this.powerUpHealValue = config.powerUpHealValue;
        this.health = config.initialHealth;
    }
    // start-up logic, called once
    public onInitialize(game: ex.Engine) {

        //set player image
        this.addDrawing('basic', Resources.playerTexture.asSprite());
        this.setDrawing('basic');
        this.setWidth(Resources.playerTexture.width);
        this.setHeight(Resources.playerTexture.height);
        this.x = game.halfDrawWidth;
        this.y = game.drawHeight * 9 / 10;
        this.scale = new Vector(0.2, 0.2);
        
        // player collides with alien
        this.collisionType = CollisionType.Passive;
        this.on('precollision', function (evt) {

            // collision with hp power up
            if (evt.other instanceof HealthPowerUp) {
                this.health += this.powerUpHealValue;

                // cant get more than max hp
                if(this.health > this.initialHealth)
                    this.health = this.initialHealth;

                evt.other.kill();
                return;
            }

            SoundHandler.playSound(Resources.playerHitSound);
            this.health -= 10;

        });

        // player movement
        game.input.keyboard.on("hold", (evt: KeyEvent) => {

            let halfPlayerWidth = this.getWidth() / 2;
            let halfPlayerHeight = this.getHeight() / 2;

            // palyer centerpoint is the middle of the player
            // player move right
            if ((Input.Keys.Right === evt.key) && (this.x + halfPlayerWidth) < game.drawWidth) {
                this.x = this.x + this.playerSpeed;

                // ups we went out of bounds (too much speed)
                if (this.x > game.drawWidth) {
                    this.x = game.drawWidth - halfPlayerWidth;
                }
            }

            // player move left
            if ((Input.Keys.Left === evt.key) && (this.x - halfPlayerWidth) > 0) {
                this.x = this.x - this.playerSpeed;

                // ups we went out of bounds (too much speed)
                if (this.x < 0) {
                    this.x = halfPlayerWidth;
                }
            }

            // player move up
            if ((Input.Keys.Up === evt.key) && (this.y - halfPlayerHeight) > 0) {
                this.y = this.y - this.playerSpeed;

                // ups we went out of bounds (too much speed)
                if (this.y < 0) {
                    this.y = halfPlayerHeight;
                }
            }

            // player move down
            if ((Input.Keys.Down === evt.key) && (this.y + halfPlayerHeight < game.drawHeight)) {
                this.y = this.y + this.playerSpeed;

                // ups we went out of bounds (too much speed)
                if (this.y > game.drawHeight) {
                    this.y = game.drawHeight - halfPlayerHeight;
                }
            }
        });

        game.input.keyboard.on("press", (evt: KeyEvent) => {

            // fire !!! - with dirty hack mybe improov
            if ((Input.Keys.Space === evt.key) && !this.isKilled()) {
                let bullet = new Bullet(this.x, this.y - this.getHeight(), BulletSource.player, 4, 8, ex.Color.Rose);
                game.add(bullet);
                SoundHandler.playSound(Resources.playerShootSound);
            }
        });
    }

    public update(engine: ex.Engine, delta: number) {
        super.update(engine, delta); // call base update logic
    }

    public killPlayer() {
        this.kill();
        
    }

    public getHealth(): number {
        return this.health;
    }
}