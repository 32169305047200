import * as ex from "excalibur";
import GameConfig from './GameConfig';

const loaderLogo = require('../res/loaderLogo.png')
const mainMenuLogo = require('../res/mainMenuLogo.png');
const helpScene = require('../res/helpScene.png');
const mainMenuBackground = require('../res/mainMenuBackground.png');
const gameBackground1 = require('../res/gameBackground1.png');
const gameBackground2 = require('../res/gameBackground2.png');
const gameBackground3 = require('../res/gameBackground3.png');
const healthbarInside = require('../res/HealthBarInside.png');
const healthbarOutside = require('../res/HealthBarOutline.png');
const powerUpHealth = require('../res/powerUpHealth.png');


const easyButton = require('../res/buttons/easyButton.png');
const easyButtonHover = require('../res/buttons/easyButtonHover.png');
const easyButtonPressed = require('../res/buttons/easyButtonPressed.png');

const mediumButton = require('../res/buttons/mediumButton.png');
const mediumButtonHover = require('../res/buttons/mediumButtonHover.png');
const mediumButtonPressed = require('../res/buttons/mediumButtonPressed.png');

const hardButton = require('../res/buttons/hardButton.png');
const hardButtonHover = require('../res/buttons/hardButtonHover.png');
const hardButtonPressed = require('../res/buttons/hardButtonPressed.png');

const helpButton = require('../res/buttons/helpButton.png');
const helpButtonHover = require('../res/buttons/helpButtonHover.png');
const helpButtonPressed = require('../res/buttons/helpButtonPressed.png');

const soundOnButton = require('../res/buttons/soundOnButton.png');
const soundOnButtonHover = require('../res/buttons/soundOnButtonHover.png');
const soundOnButtonPressed = require('../res/buttons/soundOnButtonPressed.png');

const soundOffButton = require('../res/buttons/soundOffButton.png');
const soundOffButtonHover = require('../res/buttons/soundOffButtonHover.png');
const soundOffButtonPressed = require('../res/buttons/soundOffButtonPressed.png');

const mainMenuButton = require('../res/buttons/mainMenuButton.png');
const mainMenuButtonHover = require('../res/buttons/mainMenuButtonHover.png');
const mainMenuButtonPressed = require('../res/buttons/mainMenuButtonPressed.png');

const playerTexture = require('../res/Player-sized.png');

const victoryTexture = require('../res/victory.png');
const lostTexture = require('../res/lostTexture.png');

const explosion = require('../res/explosion.png');

const soundtrack = require('../res/sounds/TitleScreenSoundtrack.mp3');
const playerShootSound = require('../res/sounds/shoot.wav');
const playerDeathSound = require('../res/sounds/PlayerDeathExplosion.wav');
const invaderKilledSound = require('../res/sounds/invaderkilled.wav');
const ufoShootSound = require('../res/sounds/ufo_highpitch.wav');
const playerHitSound = require('../res/sounds/fastinvader1.wav');

// enemys
const enemy1 = require('../res/E1.png');
const enemy2 = require('../res/E2.png');
const enemy3 = require('../res/E3.png');
const enemy4 = require('../res/E4.png');
const enemy5 = require('../res/E5.png');
const enemy6 = require('../res/E6.png');
const enemy7 = require('../res/E7.png');
const enemy8 = require('../res/E8.png');
const enemy9 = require('../res/E9.png');
const enemy10 = require('../res/E10.png');
const enemy11 = require('../res/E11.png');
const enemy12 = require('../res/E12.png');

const Resources = {
    loaderLogo: new ex.Texture(loaderLogo),
    mainMenuLogoTexture: new ex.Texture(mainMenuLogo),
    mainMenuBackgroundTexture: new ex.Texture(mainMenuBackground),
    helpSceneTexture: new ex.Texture(helpScene),
    gameBackground1: new ex.Texture(gameBackground1),
    gameBackground2: new ex.Texture(gameBackground2),
    gameBackground3: new ex.Texture(gameBackground3),
    healthbarInside: new ex.Texture(healthbarInside),
    healthbarOutside: new ex.Texture(healthbarOutside),
    powerUpHealth: new ex.Texture(powerUpHealth),
    

    easyButton: new ex.Texture(easyButton),
    easyButtonHover: new ex.Texture(easyButtonHover),
    easyButtonPressed: new ex.Texture(easyButtonPressed),

    playerTexture: new ex.Texture(playerTexture),
    explosionTexture: new ex.Texture(explosion),
    


    victoryTexture: new ex.Texture(victoryTexture),
    lostTexture: new ex.Texture(lostTexture),

    enemy1: new ex.Texture(enemy1),   
    enemy2: new ex.Texture(enemy2),   
    enemy3: new ex.Texture(enemy3),   
    enemy4: new ex.Texture(enemy4),   
    enemy5: new ex.Texture(enemy5),   
    enemy6: new ex.Texture(enemy6),   
    enemy7: new ex.Texture(enemy7),   
    enemy8: new ex.Texture(enemy8),   
    enemy9: new ex.Texture(enemy9),   
    enemy10: new ex.Texture(enemy10),   
    enemy11: new ex.Texture(enemy11),   
    enemy12: new ex.Texture(enemy12),   
  
    mediumButton: new ex.Texture(mediumButton),
    mediumButtonHover: new ex.Texture(mediumButtonHover),
    mediumButtonPressed: new ex.Texture(mediumButtonPressed),

    hardButton: new ex.Texture(hardButton),
    hardButtonHover: new ex.Texture(hardButtonHover),
    hardButtonPressed: new ex.Texture(hardButtonPressed),

    helpButton: new ex.Texture(helpButton),
    helpButtonHover: new ex.Texture(helpButtonHover),
    helpButtonPressed: new ex.Texture(helpButtonPressed),

    soundOnButton: new ex.Texture(soundOnButton),
    soundOnButtonHover: new ex.Texture(soundOnButtonHover),
    soundOnButtonPressed: new ex.Texture(soundOnButtonPressed),

    soundOffButton: new ex.Texture(soundOffButton),
    soundOffButtonHover: new ex.Texture(soundOffButtonHover),
    soundOffButtonPressed: new ex.Texture(soundOffButtonPressed),

    mainMenuButton: new ex.Texture(mainMenuButton),
    mainMenuButtonHover: new ex.Texture(mainMenuButtonHover),
    mainMenuButtonPressed: new ex.Texture(mainMenuButtonPressed),

    soundtrack: new ex.Sound(soundtrack),
    playerShootSound: new ex.Sound(playerShootSound),
    playerDeathSound: new ex.Sound(playerDeathSound),
    invaderKilledSound: new ex.Sound(invaderKilledSound),
    ufoShootSound: new ex.Sound(ufoShootSound),
    playerHitSound: new ex.Sound(playerHitSound)
};

const explosionSpriteSheet = new ex.SpriteSheet(Resources.explosionTexture, 3, 1, 500, 500);

const loader = new ex.Loader();

const config = {
    "easy": new GameConfig(.5, 1, 1, 100, 10, .05, 60),
    "medium": new GameConfig(3, 2, 1, 50, 10, .05, 30),
    "hard": new GameConfig(5, 3, 3, 70, 10, .05, 30),
    "debug": new GameConfig(2, 0, 0, 100, 10, .05, 30)
};

const gameLevelDuration = 40000;

for (let res in Resources) {
    loader.addResource(Resources[res]);
}

export {Resources, loader, config, explosionSpriteSheet, gameLevelDuration};