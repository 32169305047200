import * as ex from 'excalibur';

export class SoundHandler {

    private static mute: boolean = false;

    public static muteSound(): void {
        this.mute = true;
    }

    public static unmuteSound(): void {
        this.mute = false;
    }

    public static isMuted(): boolean {
        return this.mute;
    }

    public static playSound(sound: ex.Sound): void {
        if (!this.isMuted()) {
            sound.play().then();
        }
    }
}