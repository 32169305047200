import * as ex from 'excalibur';
import { Resources } from '../Resources';

export class Healthbar extends ex.Actor {

    private maxWidth: number;
    private percentage: number;
    public background: ex.Actor;
    private maxHealth: number;
    private currentHealth: number;
    private healthSprite: ex.Sprite;


    constructor(x: number, y: number, width: number, height: number, maxHealth: number, color?: ex.Color) {
        super(x, y, width, height, color);
        this.healthSprite = Resources.healthbarInside.asSprite();
        this.anchor.setTo(0, 0);
        this.maxWidth = Resources.healthbarInside.width;
        this.maxHealth = maxHealth;
        this.currentHealth = maxHealth;
        this.percentage = 1;

        
        this.addDrawing(this.healthSprite);

        // calc new healthbar bounds
        this.background = new ex.Actor(this.x, this.y, this.getWidth(), this.getHeight());
        this.background.setWidth(Resources.healthbarOutside.width);
        this.background.setHeight(Resources.healthbarOutside.height);
        this.background.addDrawing(Resources.healthbarOutside);
        this.background.anchor.setTo(0, 0);
    }

    public reset() {
        this.setCurrentHealth(this.maxHealth);
    }

    public setCurrentHealth(val: number) {
        this.currentHealth = val;
        this.setPercentage(this.currentHealth / this.maxHealth)
    }

    public getCurrentHealth(): number {
        return this.currentHealth;
    }

    public getPercentage(): number {
        return this.percentage;
    }

    private updateSprite() {
        this.healthSprite.width = this.maxWidth * this.percentage;
    }

    private setPercentage(percentage: number) {
        this.percentage = percentage;
        this.updateSprite();

    }

    public toString(): String {
        return "x: " + this.x + " y: " + this.y + " width: " + this.getWidth + " height " + this.getHeight;
    }

    public update(game: ex.Engine, delta: number) {
        super.update(game, delta); // call base update logic
    }
}