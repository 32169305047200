import * as ex from 'excalibur';
import {Resources, gameLevelDuration} from './Resources';
import {Button, buttonState} from './actors/Button';
import {ToggleButton, toggleButtonState} from "./actors/ToggleButton";
import {SoundHandler} from "./SoundHandler";
import {GameScene} from './GameScene';
import {config} from './Resources';
import {KeyEvent} from "excalibur/dist/Input";
import {Input} from "excalibur";

export class MainMenuScene extends ex.Scene {

    private readonly LOGO_OFFSET: number;
    private readonly DIF_BUTTON_OFFSET: number;

    private readonly DIF_BUTTON_PADDING: number;
    private readonly DIF_BUTTON_HEIGHT: number;
    private readonly DIF_BUTTON_WIDTH: number;
    private readonly DIF_BUTTON_SCALE: number;

    private readonly UTL_BUTTON_PADDING: number;
    private readonly UTL_BUTTON_HEIGHT: number;
    private readonly UTL_BUTTON_WIDTH: number;
    private readonly UTL_BUTTON_SCALE: number;

    private readonly backgroundAct: ex.Actor;
    private readonly logoAct: ex.Actor;
    private readonly menuSound: ToggleButton;
    private readonly difEasy: Button;
    private readonly difMedium: Button;
    private readonly difHard: Button;
    private readonly menuHelp: Button;

    constructor() {
        super();

        this.LOGO_OFFSET = 75;

        this.DIF_BUTTON_OFFSET = 300;
        this.DIF_BUTTON_PADDING = 10;
        this.DIF_BUTTON_HEIGHT = 220;
        this.DIF_BUTTON_WIDTH = 800;
        this.DIF_BUTTON_SCALE = 0.2;

        this.UTL_BUTTON_PADDING = -10;
        this.UTL_BUTTON_HEIGHT = 115;
        this.UTL_BUTTON_WIDTH = 115;
        this.UTL_BUTTON_SCALE = 0.4;

        // init all Buttons
        this.menuSound = new ToggleButton();
        this.difEasy = new Button();
        this.difMedium = new Button();
        this.difHard = new Button();
        this.menuHelp = new Button();

        this.backgroundAct = new ex.Actor();
        this.logoAct = new ex.Actor();

        Resources.soundtrack.loop = true;
    }

    public onInitialize(game: ex.Engine) {
        // add all actors to the scene
        game.add(this.backgroundAct);
        game.add(this.logoAct);
        game.add(this.difEasy);
        game.add(this.difMedium);
        game.add(this.difHard);
        game.add(this.menuHelp);
        game.add(this.menuSound);

        //bind images to static Actors
        this.logoAct.addDrawing(Resources.mainMenuLogoTexture);
        this.backgroundAct.addDrawing(Resources.mainMenuBackgroundTexture);

        //bind images to buttonActors
        this.difEasy.addTexture(buttonState.NotPressed, Resources.easyButton);
        this.difEasy.addTexture(buttonState.Hover, Resources.easyButtonHover);
        this.difEasy.addTexture(buttonState.Pressed, Resources.easyButtonPressed);

        this.difMedium.addTexture(buttonState.NotPressed, Resources.mediumButton);
        this.difMedium.addTexture(buttonState.Hover, Resources.mediumButtonHover);
        this.difMedium.addTexture(buttonState.Pressed, Resources.mediumButtonPressed);

        this.difHard.addTexture(buttonState.NotPressed, Resources.hardButton);
        this.difHard.addTexture(buttonState.Hover, Resources.hardButtonHover);
        this.difHard.addTexture(buttonState.Pressed, Resources.hardButtonPressed);

        this.menuHelp.addTexture(buttonState.NotPressed, Resources.helpButton);
        this.menuHelp.addTexture(buttonState.Hover, Resources.helpButtonHover);
        this.menuHelp.addTexture(buttonState.Pressed, Resources.helpButtonPressed);

        this.menuSound.addTexture(toggleButtonState.NotPressed, Resources.soundOnButton);
        this.menuSound.addTexture(toggleButtonState.Hover, Resources.soundOnButtonHover);
        this.menuSound.addTexture(toggleButtonState.Pressed, Resources.soundOnButtonPressed);
        this.menuSound.addTexture(toggleButtonState.NotPressedToggled, Resources.soundOffButton);
        this.menuSound.addTexture(toggleButtonState.HoverToggled, Resources.soundOffButtonHover);
        this.menuSound.addTexture(toggleButtonState.PressedToggled, Resources.soundOffButtonPressed);

        this.backgroundAct.anchor.setTo(0, 0);
        this.backgroundAct.scale.setTo(0.7, 0.7);

        // assign new anchor point of the logo image at the middle-top
        this.logoAct.anchor.setTo(.5, 0);
        this.logoAct.x = game.halfDrawWidth;
        this.logoAct.y = 75;
        this.logoAct.scale.setTo(0.5, 0.5);

        // arrange difficulty buttons
        this.difEasy.setHeight(this.DIF_BUTTON_HEIGHT);
        this.difEasy.setWidth(this.DIF_BUTTON_WIDTH);
        this.difEasy.x = game.halfDrawWidth;
        this.difEasy.y = this.DIF_BUTTON_OFFSET;
        this.difEasy.scale.setTo(this.DIF_BUTTON_SCALE, this.DIF_BUTTON_SCALE);

        this.difMedium.setHeight(this.DIF_BUTTON_HEIGHT);
        this.difMedium.setWidth(this.DIF_BUTTON_WIDTH);
        this.difMedium.x = game.halfDrawWidth;
        this.difMedium.y = this.difEasy.y + (this.difMedium.getHeight() * this.DIF_BUTTON_SCALE) + this.DIF_BUTTON_PADDING;
        this.difMedium.scale.setTo(this.DIF_BUTTON_SCALE, this.DIF_BUTTON_SCALE);

        this.difHard.setHeight(this.DIF_BUTTON_HEIGHT);
        this.difHard.setWidth(this.DIF_BUTTON_WIDTH);
        this.difHard.x = game.halfDrawWidth;
        this.difHard.y = this.difMedium.y + (this.difHard.getHeight() * this.DIF_BUTTON_SCALE) + this.DIF_BUTTON_PADDING;
        this.difHard.scale.setTo(this.DIF_BUTTON_SCALE, this.DIF_BUTTON_SCALE);

        // set help button at bottom left corner
        this.menuHelp.setHeight(this.UTL_BUTTON_HEIGHT);
        this.menuHelp.setWidth(this.UTL_BUTTON_WIDTH);
        this.menuHelp.x = (this.menuHelp.getWidth() * this.UTL_BUTTON_SCALE) + this.UTL_BUTTON_PADDING;
        this.menuHelp.y = game.drawHeight - (this.menuHelp.getHeight() * this.UTL_BUTTON_SCALE) - this.UTL_BUTTON_PADDING;
        this.menuHelp.scale.setTo(this.UTL_BUTTON_SCALE, this.UTL_BUTTON_SCALE);

        // set sound button bottom right corner
        this.menuSound.setHeight(this.UTL_BUTTON_HEIGHT);
        this.menuSound.setWidth(this.UTL_BUTTON_WIDTH);
        this.menuSound.x = game.drawWidth - (this.menuSound.getWidth() * this.UTL_BUTTON_SCALE) - this.UTL_BUTTON_PADDING;
        this.menuSound.y = game.drawHeight - (this.menuSound.getHeight() * this.UTL_BUTTON_SCALE) - this.UTL_BUTTON_PADDING;
        this.menuSound.scale.setTo(this.UTL_BUTTON_SCALE, this.UTL_BUTTON_SCALE);

        // assign event handlers to buttons
        this.difEasy.onClicked(function () {
            game.add('Game', new GameScene(gameLevelDuration, config.easy, 1));
            game.goToScene('Game')
        });
        this.difEasy.onPressDown(function () {
        });
        this.difEasy.onHover(function () {
        });
        this.difEasy.onStopHover(function () {
        });

        this.difMedium.onClicked(function () {
            game.add('Game', new GameScene(gameLevelDuration, config.medium, 1));
            game.goToScene('Game')
        });
        this.difMedium.onPressDown(function () {
        });
        this.difMedium.onHover(function () {
        });
        this.difMedium.onStopHover(function () {
        });

        this.difHard.onClicked(function () {
            game.add('Game', new GameScene(gameLevelDuration, config.hard, 1));
            game.goToScene('Game')
        });
        this.difHard.onPressDown(function () {
        });
        this.difHard.onHover(function () {
        });
        this.difHard.onStopHover(function () {
        });

        this.menuHelp.onClicked(function () {
            game.goToScene('Help')
        });
        this.menuHelp.onPressDown(function () {
        });
        this.menuHelp.onHover(function () {
        });
        this.menuHelp.onStopHover(function () {
        });

        this.menuSound.onClicked(function () {
            SoundHandler.unmuteSound();
            Resources.soundtrack.play().then();
        }, function () {
            SoundHandler.muteSound();
            Resources.soundtrack.stop();
        });
        this.menuSound.onPressDown(function () {
        });
        this.menuSound.onHover(function () {
        });
        this.menuSound.onStopHover(function () {
        });

        game.input.keyboard.on("press", (evt: KeyEvent) => {

            // fire !!! - with dirty hack mybe improov
            if (Input.Keys.M === evt.key) {
                if (SoundHandler.isMuted()) {
                    SoundHandler.unmuteSound();
                } else {
                    SoundHandler.muteSound();
                }
            }
        });
    }

    // each time the scene is entered (Engine.goToScene)
    public onActivate() {
        Resources.soundtrack.play().then();
    }

    // each time the scene is exited (Engine.goToScene)
    public onDeactivate() {
        Resources.soundtrack.stop();
    }
}